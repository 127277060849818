export const NOISY_MIC = "NOISY_MIC";
export const NOISY_MIC_MUTED = "NOISY_MIC_MUTED";
export const SCREEN_SHARING_STARTED = "SCREEN_SHARING_STARTED";
export const SCREEN_SHARING_STOPPED = "SCREEN_SHARING_STOPPED";
export const SCREEN_SHARE_STARTED = "SCREEN_SHARE_STARTED";
export const SCREEN_SHARE_ENDED = "SCREEN_SHARE_ENDED";
export const START_SCREEN_RECORDING = "START_SCREEN_RECORDING";
export const STOP_SCREEN_RECORDING = "stopScreenRecording";
export const PARTICIPANT_MUTED = "participantMuted";
export const TRYING_TO_START_SCREEN_RECORDING = "tryingToStartScreenRecording";
export const SWITCH_TO_VIDEO_SESSION_REQUEST = "CONVERT_TO_VIDEO_REQUEST";
export const SWITCH_TO_VIDEO_SESSION_CONVERTED = "SWITCH_TO_VIDEO_SESSION_CONVERTED";
export const SWITCH_TO_VIDEO_SESSION_RESPONSE = "CONVERT_TO_VIDEO_RESPONSE";
export const TRYING_TO_STOP_START_SCREEN_RECORDING = "tryingToStopStartScreenRecording";
export const END_SESSION_FOR_ALL = "endSessionForAll";
export const initOptions = {
  disableAudioLevels: false,
  desktopSharingChromeDisabled: false,
  desktopSharingChromeExtId: "kglhbbefdnlheedjiejgomgmfplipfeb", // Id of desktop streamer Chrome extension
  desktopSharingSources: ["screen", "window"],
  // desktopSharingChromeMinExtVersion: "0.1",
  desktopSharingFirefoxDisabled: false,
  enableTalkWhileMuted: true,
  enableNoisyMicDetection: true,
  channelLastN: 15,
  lastNLimits: {
    5: 20,
    30: 15,
    50: 10,
    70: 5,
    90: 2,
  },
  p2p: {
    enabled: false,
  },
};

// const options = {
//   hosts: {
//     domain: "rtc-uss.cometchat.io",
//     muc: "conference.rtc-uss.cometchat.io",
//   },
//   enableNoisyMicDetection: true,
//   bosh: "https://rtc-uss.cometchat.io/http-bind",
//   clientNode: "http://jitsi.org/jitsimeet",
//   channelLastN,
//   enableTalkWhileMuted: true,
// };

export const confOptions = {
  openBridgeChannel: "websocket",
  enableNoisyMicDetection: true,
  channelLastN: 10,
  websocketKeepAlive: -1,
  testing: {
    octo: { probability: 1 },
  },
  e2eping: { pingInterval: -1 },
  deploymentInfo: {
    userRegion: "eu-west-1",
  },

  enableTalkWhileMuted: true,
  p2p: {
    enabled: false,
  },
  hiddenDomain: "hidden.rtc-eu.cometchat.io",
  fileRecordingsEnabled: true,
};

export const options = {
  hosts: {
    domain: "rtcb-test.cometchat.io",
    focus: "focus.rtcb-test.cometchat.io",
    muc: "conference.rtcb-test.cometchat.io",
  },
  websocket: "wss://xmpp.rtcb-test.cometchat.io/xmpp-websocket",
  bosh: "https://xmpp.rtcb-test.cometchat.io/http-bind",
};
// var options = {
//   hosts: {
//     domain: this.getDomain(),
//     muc: this.getMUC(),
//   },
//   enableNoisyMicDetection: true,
//   bosh: this.getBosh(),
//   clientNode: "http://jitsi.org/jitsimeet",
//   channelLastN,
//   enableLayerSuspension: true,
//   enableTalkWhileMuted: true,
// };

// var options = {
//   hosts: {
//     domain: "rtc-custom.cometchat.io",
//     focus: "focus.rtc-custom.cometchat.io",
//     muc: "conference.rtc-custom.cometchat.io",
//   },
//   websocket: "wss://xmpp.rtc-custom.cometchat.io/xmpp-websocket",
//   bosh: "https://xmpp.rtc-custom.cometchat.io/http-bind",
// };

//windows event
export const EVENT_BEFOREUNLOAD = "beforeunload";
export const EVENT_MESSAGE = "message";
export const EVENT_UNLOAD = "unload";
//event types
export const COMETCHAT_ACTION_MESSAGE = "cometchat_action_message";
//event action
export const PAUSE_VIDEO = "pauseVideo";
export const UNPAUSE_VIDEO = "unPauseVideo";
export const MUTE_AUDIO = "muteAudio";
export const UNMUTE_AUDIO = "unMuteAudio";
export const SCREEN_SHARE_START = "startScreenShare";
export const SCREEN_SHARE_STOP = "stopScreenShare";
export const END_CALL = "endCall";
export const ON_USER_JOINED = "onUserJoined";
export const ON_USERLIST_CHANGED = "onUserListChanged";
export const ON_USER_LEFT = "onUserLeft";
export const CHAT = "chat";
export const HANG_UP = "hangup";
export const INITIAL_DEVICE_LIST = "initialDeviceList";
export const ON_DEVICE_CHANGE = "onDeviceChange";
export const CHANGE_AUDIO_INPUT = "changeAudioInput";
export const CHANGE_AUDIO_OUTPUT = "changeAudioOutput";
export const CHANGE_VIDEO_INPUT = "changeVideoInput";
export const SWITCH_MODE = "switchMode";
export const ON_MODE_CHANGE = "onModeChanged";
export const START_RECORDING = "startRecording";
export const STOP_RECORDING = "stopRecording";
export const ON_RECORDING_TOGGLED = "onRecordingToggled";
export const ON_USER_MUTED = "onUserMuted";
export const OPEN_VIRTUAL_BACKGROUND_MENU = "openVirtualBackgroundMenu";
export const SET_BACKGROUND_BLUR = "setBackgroundBlur";
export const SET_BACKGROUND_IMAGE = "setBackgroundImage";
export const SWITCHED_TO_VIDEO_CALL = "onCallSwitchedToVideo";
export const SWITCH_TO_VIDEO_CALL = "switchedToVideo";

//orientation
export const ORIENTATION_LANDSCAPE = "Landscape";
export const ORIENTATION_PORTRAIT = "Portrait";

//Track types
export const TRACK_VIDEO = "video";
export const TRACK_AUDIO = "audio";
export const TRACK_DESKTOP = "desktop";

//devices type
export const AUDIO_INPUT_DEVICES = "audioinput";
export const VIDEO_INPUT_DEVICES = "videoinput";
export const AUDIO_OUTPUT_DEVICES = "audiooutput";

export const LocalizedString = {
  REQUESTING_TO_SWITCH_VIDEO_CALL: "Requesting to switch to a video call",
  REQUESTING_TO_SWITCH_VIDEO_CALL_REJECTED: "Requesting to switch to a video call rejected",
  SWITCH_TO_VIDEO_CALL: "Switch to video call",
  SELECT_VIDEO_SOURCE: "Select video source",
  SELECT_INPUT_AUDIO_SOURCE: "Select input audio source",
  SELECT_OUTPUT_AUDIO_SOURCE: "Select output audio source",
  SELECT_MODE: "Select mode",
  CALL_RECORDING_STARTED_MSG: "This call is being recorded",
  USER_JOINED_CALL_MSG: "has joined the call",
  CALL_RECORDING_ENDED_MSG: "Call recording has been stopped",
  USER_LEFT_CALL_MSG: "has left the call",
  USERS_LEFT_CALL_MSG: "__USERS__ and __USER__ has left the call",
  CAMERA_ERROR_MSG:
    "No camera detected. Kindly fix your camera or allow permission to use the camera",
  MIC_ERROR_MSG:
    "No microphone detected. Kindly fix your microphone or allow permission to use the microphone",
  CAMERA_MIC_ERROR_MSG:
    "No microphone & camera detected. Kindly fix your microphone & camera or allow permission to use the microphone & camera",
  RECORDING_TEXT: "Recording",
  TRYING_TO_START_RECORDING_TEXT: "Trying to start recording...",
  JOINING_TEXT: "Joining...",
  CONNECTION_ERROR_TEXT: "Couldn't connect, something went wrong!",
  UN_MUTE_AUDIO_TEXT: "Unmute audio",
  MUTE_AUDIO_TEXT: "Mute audio",
  END_CALL_TEXT: "End call",
  START_RECORDING_TEXT: "Start recording",
  STOP_RECORDING_TEXT: "Stop recording",
  START_SCREEN_SHARE_TEXT: "Start screenShare",
  STOP_SCREEN_SHARE_TEXT: "Stop screenShare",
  MUTE_VIDEO_TEXT: "Mute video",
  UN_MUTE_VIDEO_TEXT: "Unmute video",
  SELECT_LAYOUT_HEADER: "Select layout",
  NO_OF_USER_IN_TILE_TEXT: "Select the number of users to be shown in Tile mode",
  CHANGE_AUDIO_SOURCE: "Change audio source",
  CHANGE_AUDIO_SOURCE_DESC: "You can select the desired audio input and output",
  AUDIO_INPUT_DEVICE_LABEL: "Audio input device",
  NO_AUDIO_DEVICE_AVAILABLE: "No device available",
  AUDIO_OUTPUT_DEVICE_LABEL: "Audio output device",
  CHANGE_VIDEO_SOURCE: "Change video source",
  CHANGE_VIDEO_SOURCE_DESC: "You can select the desired video device",
  VIDEO_DEVICES_LABEL: "Video device",
  FULL_SCREEN: "Full screen",
  EXIT_FULL_SCREEN: "Exit full screen",
  CHANGE_LAYOUT: "Change layout",
  AUDIO_SETTING: "Audio setting",
  VIDEO_SETTING: "Video setting",
  USER_LIST: "User list",
  MORE: "More",
  MUTE_USER: "Mute",
  PIN_USER: "Pin",
  UNPIN_USER: "Unpin",
  UN_MUTE_USER: "Unmute",
  MUTED: "muted",
  IOS_BROWSER_WARNING:
    "We recommend to use Safari browser for a better experience with video calls",
  PEOPLE_TEXT: "People",
  SPOTLIGHT_DETAIL_TEXT1: "Video of",
  SPOTLIGHT_DETAIL_TEXT2: "Active Speaker",
  SPOTLIGHT_DETAIL_TEXT3:
    "will automatically be displayed on the main screen. If you wish to see a particular person, please click on the name below to",
  SPOTLIGHT_DETAIL_TEXT4: "Pin or Unpin",
  STOP_RECORDING_ERROR: "Recording can only be stopped by the recorder",
  VIRTUAL_BACKGROUND: "Virtual Background",
  SCREEN_SHARE_MSG: "You are currently sharing your screen.",
  RECONNECTING_TEXT: "Connection lost. Trying to reconnect...",
  NO_NETWORK_TEXT: "No network connection. Please check your connection.",
  NOISY_MIC: "You have a noisy mic.",
  TALK_WHILE_MUTED: "Your mic is on mute.",
  NO_OF_USER_IN_PAGINATED_TEXT: "Select the maximum number of user displayed on one page",
  SCREEN_SHARE_POSTFIX: "(Screen)",
};

export const videoSettings = {
  fit: "contain",
  fitButton: { visible: false, position: "bottom-right" },
  nameLabel: { visible: true, position: "bottom-left", background: "rgba(27, 27, 27, 0.4)" },
  network: { visible: false, position: "bottom-right" },
};

export const CALLING_MODES = {
  TILE: "TILE",
  SIDEBAR: "SIDEBAR",
  PRESENTER: "PRESENTER",
};
